import { ActionType, getType } from 'typesafe-actions';

import { actions } from './actions';
import { actions as apiActions } from '../api/actions';
import { FixtureType } from '../../components/ViewAndEdit/editorConstants';
import { BincapRecord, FacingGroup, LocatedFixture, WarningData, WarningsData, Block } from '../job/types';
import { PDFDownloadStatus } from '../../types/responses';

export enum LegendClickTrack {
  None,
  First,
  Second
}

export enum EditMode {
  SKU = 'SKU',
  FIXTURE = 'FIXTURE',
  SEGMENT = 'SEGMENT'
}

export type ReviewAndEditState = {
  isSkuNumberShown: boolean;
  isDisableRedOverlapHightlights: boolean;
  isHidePegholes: boolean;
  isProductImagesShown: boolean;
  selectedSegment: number | null;
  displayAlertsIndicator: boolean;
  isLegendOpened: boolean;
  isFiltersOpened: boolean;
  isPopDrawerOpened: boolean;
  isHighlightProductOpened: boolean;
  legendClickTrack: LegendClickTrack;
  isClipboardOpened: boolean;
  isWorkbenchOpened: boolean;
  isAddingFacingModalShow: boolean;
  isMerchNotesExpanded: boolean;
  isRestart: boolean;
  editMode: boolean; //TODO use instead of Context value
  edit: EditMode | null;
  selectedFacing: number | null;
  selectedFacingsGroup: string;
  selectedFixtureIds: Array<number>;
  history: Array<{
    removedId: Array<string>;
    newFacings: Array<FacingGroup>;
    removedClipboardId: Array<string>;
    newClipboardFacings: Array<FacingGroup>;
    removedFixtureIds: Array<number>;
    newFixtures: Array<LocatedFixture>;
    alerts: WarningsData;
    newClipboardFixturesFacings: Array<FacingGroup>;
    newClipboardFixtures: Array<LocatedFixture>;
    removedClipboardFixturesFacings: Array<string>;
    removedClipboardFixtures: Array<number>;
    bincapConstraints: Array<BincapRecord>;
    newWorkbenchFacings: Array<FacingGroup>;
    removedWorkbenchFacingId: Array<string>;
    blocks: Array<Block>;
  }>
  historyStep: number;
  visualizerAlert: WarningData | null;
  isAlertOpened: boolean;
  isToggleAlert: boolean;
  selectedFacingEntities: Record<string, Array<number>>;
  selectedFixtureType: FixtureType | string;
  selectedDistrictType: string;
  alerts?: WarningsData;
  isStrikeZoneEnabled: boolean;
  removedFacings: Array<string>;
  activeClipboardSku: Array<string>;
  isPogHeightEnabled: boolean;
  workbenchScrollWithPog: boolean;
  pdfExportStatus: null | PDFDownloadStatus;
};

const INITIAL_DRAWERS_STATE = {
  isLegendOpened: false,
  isFiltersOpened: false,
  isPopDrawerOpened: false,
  isHighlightProductOpened: false,
  legendClickTrack: LegendClickTrack.None
};

export const historyStepItem = {
  newFacings: [],
  removedId: [],
  removedClipboardId: [],
  newClipboardFacings: [],
  alerts: [],
  removedFixtureIds: [],
  newFixtures: [],
  newClipboardFixturesFacings: [],
  newClipboardFixtures: [],
  removedClipboardFixturesFacings: [],
  removedClipboardFixtures: [],
  bincapConstraints: [],
  newWorkbenchFacings: [],
  removedWorkbenchFacingId: [],
  blocks: [],
};

export const INITIAL_STATE: ReviewAndEditState = {
  ...INITIAL_DRAWERS_STATE,
  isClipboardOpened: false,
  isDisableRedOverlapHightlights: false,
  isHidePegholes: false,
  isMerchNotesExpanded: false,
  isRestart: false,
  isSkuNumberShown: true,
  selectedSegment: null,
  isAddingFacingModalShow: false,
  isProductImagesShown: false,
  selectedFacing: null,
  selectedFacingsGroup: '',
  selectedFixtureIds: [],
  edit: null,
  history: [historyStepItem],
  historyStep: 0,
  visualizerAlert: null,
  isAlertOpened: false,
  displayAlertsIndicator: true,
  editMode: false,
  selectedFacingEntities: {},
  selectedFixtureType: '',
  selectedDistrictType: '',
  isToggleAlert: true,
  isStrikeZoneEnabled: false,
  removedFacings: [],
  activeClipboardSku: [],
  isPogHeightEnabled: false,
  isWorkbenchOpened: false,
  workbenchScrollWithPog: true,
  pdfExportStatus: null
};

export function reviewAndEdit(
  state: ReviewAndEditState = INITIAL_STATE,
  action: ActionType<typeof actions | typeof apiActions>
): ReviewAndEditState {
  switch (action.type) {
    case getType(actions.setSkuNumberToShown): {
      return {
        ...state,
        isSkuNumberShown: action.payload,
      };
    }
    case getType(actions.setDisableRedOverlapHightlights): {
      return {
        ...state,
        isDisableRedOverlapHightlights: action.payload,
      };
    }
    case getType(actions.setHidePegholes): {
      return {
        ...state,
        isHidePegholes: action.payload,
      };
    }
    case getType(actions.setShowProductImages): {
      return {
        ...state,
        isProductImagesShown: action.payload,
      };
    }
    case getType(actions.setSelectedSegment): {
      return {
        ...state,
        selectedSegment: action.payload || null,
      };
    }
    case getType(actions.setLegendOpened): {
      return {
        ...state,
        isPopDrawerOpened: false,
        isLegendOpened: action.payload
      };
    }
    case getType(actions.setProductHighlightOpened): {
      return {
        ...state,
        isPopDrawerOpened: false,
        isHighlightProductOpened: action.payload
      };
    }
    case getType(actions.setFiltersOpened): {
      return {
        ...state,
        isPopDrawerOpened: false,
        isFiltersOpened: !state.isFiltersOpened
      };
    }
    case getType(actions.setPopDrawerOpened): {
      return {
        ...state,
        ...INITIAL_DRAWERS_STATE,
        legendClickTrack: state.legendClickTrack,
        isClipboardOpened: false,
        isPopDrawerOpened: action.payload !== undefined ? action.payload : !state.isPopDrawerOpened
      };
    }
    case getType(actions.setLegendClickTrack): {
      return {
        ...state,
        isPopDrawerOpened: false,
        legendClickTrack: action.payload
      };
    }
    case getType(actions.setClipboardOpened): {
      return {
        ...state,
        isPopDrawerOpened: false,
        isClipboardOpened: action.payload
      };
    }
    case getType(actions.setMerchNotesExpanded): {
      return {
        ...state,
        ...INITIAL_DRAWERS_STATE,
        legendClickTrack: state.legendClickTrack,
        isMerchNotesExpanded: action.payload
      };
    }
    case getType(actions.setRestart): {
      return {
        ...state,
        isRestart: action.payload
      };
    }
    case getType(apiActions.getOptimizationSolverOutput.success):
    case getType(apiActions.autoSavedPogSolverOutput.success):
    case getType(apiActions.getEditedSolverOutput.success):
    case getType(apiActions.aggregatedSolverOutput.success):
    case getType(apiActions.saveNewEditedPog.success):
    case getType(apiActions.updateEditedPog.success): {
      return {
        ...INITIAL_STATE,
        displayAlertsIndicator: state.displayAlertsIndicator,
        isClipboardOpened: state.isClipboardOpened,
        isWorkbenchOpened: state.isWorkbenchOpened,
        editMode: state.editMode,
        edit: state.edit
      };
    }

    case getType(actions.setReset): {
      return {
        ...INITIAL_STATE,
        displayAlertsIndicator: state.displayAlertsIndicator,
        isClipboardOpened: false,
        isWorkbenchOpened: false,
        editMode: state.editMode,
        edit: state.edit
      };
    }

    case getType(actions.setSelectedFacing): {
      return {
        ...state,
        selectedFacing: action.payload
      };
    }

    case getType(actions.setSelectedFacingsGroup): {
      return {
        ...state,
        selectedFacingsGroup: action.payload
      };
    }

    case getType(actions.setNotificationModal): {
      return {
        ...state,
        isAddingFacingModalShow: action.payload
      };
    }

    case getType(actions.undoHistoryStep): {
      return {
        ...state,
        historyStep: Math.max(state.historyStep - 1, 0),
        selectedFacingsGroup: '',
        selectedFacing: null,
        selectedFacingEntities: {},
        selectedFixtureType: '',
        selectedDistrictType: '',
        selectedFixtureIds: []
      };
    }

    case getType(actions.redoHistoryStep): {
      return {
        ...state,
        historyStep: Math.min(state.historyStep + 1, state.history.length - 1),
        selectedFacingsGroup: '',
        selectedFacing: null,
        selectedFacingEntities: {},
        selectedFixtureType: '',
        selectedDistrictType: '',
        selectedFixtureIds: []
      };
    }

    case getType(actions.setHistory): {
      const history = state.history.slice(0, state.historyStep + 1).concat(action.payload);

      return {
        ...state,
        history,
        historyStep: history.length - 1,
        selectedFacingsGroup: '',
        selectedFacing: null,
        selectedFacingEntities: {},
        selectedFixtureType: '',
        selectedDistrictType: '',
        selectedFixtureIds: []
      };
    }

    case getType(actions.setVisualizerAlert): {
      return {
        ...state,
        visualizerAlert: action.payload
      };
    }

    case getType(actions.setAlertOpened): {
      return {
        ...state,
        isAlertOpened: action.payload
      };
    }

    case getType(actions.setToggleAlert): {
      return {
        ...state,
        isToggleAlert: action.payload
      };
    }

    case getType(actions.setStrikeZoneEnabled): {
      return {
        ...state,
        isStrikeZoneEnabled: action.payload
      };
    }

    case getType(actions.setPogHeightEnabled): {
      return {
        ...state,
        isPogHeightEnabled: action.payload
      };
    }

    case getType(actions.setAlerts): {
      return {
        ...state,
        alerts: action.payload
      };
    }

    case getType(actions.setAlert): {
      const { payload } = action;
      const { alerts } = state;

      if (!alerts) {
        return {
          ...state,
          alerts: [payload]
        };
      }

      const warningIndexState = alerts ? alerts.findIndex(a => a.warningType === payload.warningType) : -1;

      if (warningIndexState === -1) {
        return {
          ...state,
          alerts: [...alerts.slice(0, 0), payload, ...alerts.slice(0)]
        };
      }

      const stateAlert = alerts[warningIndexState];
      const newAlert = { ...stateAlert, data: [...stateAlert.data, ...payload.data], selected: true } as WarningData;

      return {
        ...state,
        alerts: [...alerts.slice(warningIndexState, 0), newAlert, ...alerts.slice(warningIndexState + 1)]
      };
    }

    case getType(actions.setAlertsIndicator): {
      return {
        ...state,
        displayAlertsIndicator: action.payload
      };
    }

    case getType(actions.setEdit): {
      return {
        ...state,
        ...INITIAL_DRAWERS_STATE,
        legendClickTrack: state.legendClickTrack,
        editMode: action.payload
      };
    }

    case getType(actions.setEditType): {
      return {
        ...state,
        ...INITIAL_DRAWERS_STATE,
        legendClickTrack: state.legendClickTrack,
        edit: action.payload,
        selectedFacingEntities: {},
        selectedFixtureType: '',
        selectedDistrictType: '',
        selectedFixtureIds: []
      };
    }

    case getType(actions.setFacingsEntities): {
      return {
        ...state,
        selectedFixtureIds: [],
        selectedFacingEntities: action.payload.entities,
        selectedFixtureType: action.payload.fixtureType,
        selectedDistrictType: action.payload.districtType,
      };
    }

    case getType(actions.setSelectedFixtureType): {
      return {
        ...state,
        selectedFixtureType: action.payload
      };
    }

    case getType(actions.setSelectedFixtureIds): {
      return {
        ...state,
        selectedFacingEntities: {},
        selectedFixtureIds: action.payload.fixtureIds,
        selectedFixtureType: action.payload.fixtureType
      };
    }

    case getType(apiActions.updateEditedPog.request):
    case getType(actions.selectedReset): {
      return {
        ...state,
        selectedFacingEntities: {},
        selectedFixtureType: '',
        selectedDistrictType: '',
        selectedFixtureIds: []
      };
    }

    case getType(actions.setRemovedFacings): {
      return {
        ...state,
        removedFacings: action.payload
      };
    }

    case getType(actions.setActiveClipboardSku): {
      return {
        ...state,
        activeClipboardSku: action.payload,
        removedFacings: [],
        isClipboardOpened: true
      };
    }

    case getType(actions.setWorkbenchOpened): {
      return {
        ...state,
        isWorkbenchOpened: action.payload
      };
    }

    case getType(actions.setWorkbenchScrollWithPog): {
      return {
        ...state,
        workbenchScrollWithPog: action.payload
      };
    }

    case getType(actions.removedReset): {
      return {
        ...state,
        removedFacings: [],
        activeClipboardSku: []
      };
    }

    case getType(actions.setExportStatus):
    case getType(apiActions.downloadOutputFiles.success): {
      return {
        ...state,
        pdfExportStatus: action.payload
      };
    }

    default:
      return state;
  }
}
