import { createAction } from 'typesafe-actions';

import { AttributesColors, BlockingAttributesI } from './types';

export const actions = {
  setFilterInfo: createAction('planogramFeilter/SET_FILTER_INFO')<string>(),
  setFilter: createAction('planogramFeilter/SET_FILTER')<string[]>(),
  setInitialJob: createAction('user/SET_INITIAL_JOB')<void>(),
  setInitialJobs: createAction('user/SET_INITIAL_JOBS')<void>(),
  setBlockingAttributes: createAction('blockingFilter/SET_FILTER_ATTRIBUTES')<BlockingAttributesI>(),
  setBlockingColorAttributes: createAction('blockingFilter/SET_FILTER_COLOR_ATTRIBUTES')<AttributesColors>(),
};
