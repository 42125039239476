import { createAction } from 'typesafe-actions';

import { SortKey } from './reducer';
import { Checkboxes } from '../../types/models';

export const actions = {
  setSortBy: createAction('user/SET_SORT_BY')<SortKey>(),
  setStatusFilter: createAction('user/SET_STATUS_FILTER')<string[]>(),
  setInitialState: createAction('user/SET_DET_INITIAL_STATE')<undefined>(),
  setworkflowTooltips: createAction('user/WORKFLOW_TOOLTIP')<boolean>(),
  setAssortmentFields: createAction('user/ASSORTMENT_FIELDS')<{preAssortment?: Checkboxes, postAssortment?: Checkboxes}>(),
  setLabels: createAction('user/UPDATE_USER_SETTINGS_LABEL')<any>(),
  setsearchValueForVisualizer: createAction('user/SET_SEARCH_VALUE_FOR_VISUALIZER')<string>(),
  setVisualizerSearchEnabled: createAction('user/SET_VISUALIZER_SEARCH_ENABLED')<boolean>(),
  setPogDashboardStatus: createAction('user/SET_POGDASHBOARD_STATUS')<string[]>(),
  setLanguage: createAction('user/SET_LANGUAGE')<'fr' | 'en'>(),
};
