import React from 'react';

import { apiClientJava, fetchClientJava } from '../../../state';
import { FileParams, Props, ReleaseNotesType, State, WhatsNewType } from './HomePage.types';

export const homePageContext = React.createContext<any>({});
export class HomePageProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.getReleaseNotesItems = this.getReleaseNotesItems.bind(this);
    this.getWhatsNewItems = this.getWhatsNewItems.bind(this);
    this.getReleaseNotesFile = this.getReleaseNotesFile.bind(this);
    this.getWhatsNewFile = this.getWhatsNewFile.bind(this);
    this.setWhatsNewItems = this.setWhatsNewItems.bind(this);
    this.setReleaseNotesItems = this.setReleaseNotesItems.bind(this);

    this.state = {
      context: {
        releaseNotesItems: [],
        whatsNewItems: [],
        getReleaseNotesItems: this.getReleaseNotesItems,
        getWhatsNewItems: this.getWhatsNewItems,
        getReleaseNotesFile: this.getReleaseNotesFile,
        getWhatsNewFile: this.getWhatsNewFile,
        setWhatsNewItems: this.setWhatsNewItems,
        setReleaseNotesItems: this.setReleaseNotesItems
      },
    };
  }

  async getWhatsNewItems(language: string) {
    const response = await apiClientJava.getWhatsNew(language.toLocaleUpperCase());

    this.setState((prevState: State) => ({
      context: {
        ...prevState.context,
        whatsNewItems: response,
      },
    }));
  }

  async getWhatsNewFile(params: FileParams) {
    try {
      await fetchClientJava.getWhatsNewPDF(params);
    } catch (err) {
      console.log(err);
    }
  }

  async getReleaseNotesItems(language: string) {
    const response = await apiClientJava.getReleaseNotes(language.toUpperCase());

    this.setState((prevState: State) => ({
      context: {
        ...prevState.context,
        releaseNotesItems: response,
      },
    }));
  }

  async setReleaseNotesItems(data: ReleaseNotesType[]) {

    this.setState((prevState: State) => ({
      context: {
        ...prevState.context,
        releaseNotesItems: data,
      },
    }));
  }

  setWhatsNewItems(data: WhatsNewType[]) {
    this.setState((prevState: State) => ({
      context: {
        ...prevState.context,
        whatsNewItems: data,
      },
    }));
  }

  async getReleaseNotesFile(params: FileParams) {
    try {
      await fetchClientJava.getReleaseNotesPDF(params);
    } catch (err) {
      console.log(err);
    }
  }

  render(): JSX.Element {
    return (
      <homePageContext.Provider value={this.state.context}>
        {this.props.children}
      </homePageContext.Provider>
    );
  }
}
